import { $, $$ } from './helper/utils';
import outline from './helper/outline';
import SmoothScroll from 'smoothscroll-polyfill';
import Cookies from 'js-cookie'

SmoothScroll.polyfill();
outline();

export default function () {

    if ($('.scrolltop') !== null) {
        const upbtn = document.getElementById('scrolltop');

        upbtn.addEventListener('click', () => {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }, { passive: true });

        var lastScrollTop = 0;

        var x = 0;

        window.addEventListener('scroll', () => {
            var st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                document.getElementById('scrolltop').style.bottom = '-100%';
            } else {
                document.getElementById('scrolltop').style.bottom = '20px';
            }
            lastScrollTop = st;
        }, false);
    }

    const loadDesktopNav = () => {
        import('./components/navigation.js').then(navigation => {
            return navigation.init();
        }).catch(err => {
            console.warn(`Chunk navigation.js loading failed: ${err}`);
        });
    }

    loadDesktopNav();

    if ($('.counter-box') !== null) {
        import('./components/countUp.js').then(vehicles => {
            return vehicles.init();
        }).catch(err => {
            console.warn(`Chunk countUp.js loading failed: ${err}`);
        })
    }
    const loadMobileNav = () => {
        import('./components/mobileNav.js').then(mobileNav => {
            return mobileNav.init();
        }).catch(err => {
            console.warn(`Chunk mobileNav.js loading failed: ${err}`);
        });
    }

    loadMobileNav();

    if ($$('.open-modal, [data-modal-self]').length > 0) {
        import('./components/modal.js')
            .then((modal) => {
                const modals = $$('.open-modal, [data-modal-self]');

                for (let i = 0; i < modals.length; i++) {
                    const el = modals[i];
                    const Modal = modal.default(el);

                    if (el.hasAttribute('data-modal-self')) {
                        // skip when in veo edit mode
                        if (document.body.id === 'veoedit') {
                            continue;
                        }

                        const cookieName = `modal${el.dataset.modalNr}`;
                        const modalShown = Cookies.get(cookieName);

                        if (!modalShown) {
                            setTimeout(() => {
                                Modal.show();
                            }, 500);

                            Cookies.set(cookieName, 1, { expires: 1, sameSite: 'strict' });
                        }
                    } else {
                        el.addEventListener('click', Modal.show);
                    }
                }
            })
            .catch((err) => {
                console.warn('Chunk modal.js loading failed', err);
            });
    }

    if ($('.nav-tabs') !== null || $('.accordion') !== null) {
        const fadeTab = (e) => {
            const href = e.relatedTarget.getAttribute('href');
            TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
        };

        import('../libs/bs-4-native').then((bsn) => {
            if ('bootstrapNative' in window === false) {
                window.bootstrapNative = bsn;
            }
            const tabs = $$('.nav-tabs .tab-link');
            for (let i = 0; i < tabs.length; i++) {
                const el = tabs[i];

                // fade in new tab content
                el.addEventListener('hide.bs.tab', fadeTab, false);
            }
        }).catch((err) => {
            console.warn('Chunk bs4-native.js loading failed', err);
        });
    }
}
